<template>
	<div>
		<!-- Scenes -->
		<div v-if="genres.length > 0" class="padding-top-md">
			<ion-list>
				<ion-item v-for="genre in genres" :key="genre" button detail :href="'/admin-genre/'+genre.id">
					<div tabindex="0"></div>
					{{genre.name}}
				</ion-item>
			</ion-list>
		</div>
	</div>
</template>

<script>
import { IonList, IonItem } from '@ionic/vue';
import { defineComponent, computed, ref, onMounted } from 'vue';
import { useStore } from "vuex";
//import { image, trash } from 'ionicons/icons';
import apiClient from '../services/api';
import { useRouter } from 'vue-router';

export default defineComponent({
	name: 'AdminGenreList',
	props: [],
	components: {
		IonList, IonItem
	},
	setup() {
		const router = useRouter();
		const store = useStore();
		const authUser = computed(() => store.state.authUser);
		const genres = ref([]);

		onMounted(() => {
			getGenres()
		})

		function getGenres() {
			//presentLoading()
			apiClient.get('/api/get/genres', {
				headers: {
					'Authorization':  `Bearer ${store.state.authToken}`
				}
			}).then(response => {
				genres.value = response.data
			}).catch(error => {
				console.log(error)  
			});      
		}

		function editGenre(id) {
			router.push('/admin-genre/'+id)
		}

		return {
			authUser, genres, editGenre, router
		}
	},
});
</script>